// Styles SCSS
import "../sass/app.scss";

// jQuery
// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

// Masonry layout
import Masonry from "./utils/masonry";

// SVG polyfiil
import "svgxuse";

// Flash Info
import "jquery.marquee";

// Accordions
import Accordion from "accordion-js";

//Tabs
import { Tabs } from "./utils/tabs";

// pushbar
import Pushbar from "./utils/pushbar";

// Lightbox
import "./utils/fancybox";

// LazyLoad
import lazyLoadInit from "./lazyload-init";

lazyLoadInit();
$(document).ready(() => {
  document.getElementById("toggle-search").onclick = function () {
    document
      .getElementById("searchform-responsive")
      .classList.toggle("visible");
  };

  if (document.querySelector(".accordion-container") !== null) {
    new Accordion(".accordion-container");
  }
  if (document.querySelector(".tabs-container") !== null) {
    new Tabs({
      elem: "tabs",
      open: 0,
    });
  }
  $("#submit-filter-annuaire").on("click", function (e) {
    e.preventDefault;

    var filter = $("#filter-annuaires");
    var loading = $(".load-bar");
    $.ajax({
      url: filter.attr("action"),
      data: filter.serialize(),
      type: "POST",
      action: "ajax_function_annuaire",
      beforeSend: function () {
        $(".annuaires").html("");
        loading.toggleClass("invisible");
      },
      success: function (data) {
        loading.toggleClass("invisible");
        $(".annuaires").html(data);
      },
    });
    return false;
  });

  new Pushbar({
    blur: true,
    overlay: true,
  });

  $('[data-fancybox="galerie"]').fancybox({
    thumbs: {
      autoStart: true,
    },
  });

  if (document.querySelector(".galerie") !== null) {
    const grid = document.querySelector(".galerie");
    new Masonry(grid, {
      itemSelector: ".galerie__item",
      columnWidth: 280,
      gutter: 10,
    });
  }

  $("#load-older-posts").on("click", function (e) {
    // prevent new page load
    e.preventDefault();
    // store next page number
    var next_page = $(this).attr("href");
    console.log(next_page);

    // remove older posts button from DOM
    //$(this).remove();
    // ajax older posts below existing posts
    $(".annuaires").after($("<div/>").load(next_page + " .annuaires__item"));
  });

  $(window).scroll(function () {
    if ($(this).scrollTop()) {
      $("#toTop").fadeIn();
    } else {
      $("#toTop").fadeOut();
    }
  });

  jQuery("#amazon-polly-audio-table").prependTo(".main-int__right");

  $("#toTop").click(function () {
    //1 second of animation time
    //html works for FFX but not Chrome
    //body works for Chrome but not FFX
    //This strange selector seems to work universally
    $("html, body").animate({ scrollTop: 0 }, 1000);
  });
});
